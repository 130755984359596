import { useNavigate } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import PageLayout from '../layouts/PageLayout';

const Navigation = styled('div')`
  display: flex;
  gap: 10em;
  justify-content: center;
  
  button {
    font-size: 30px;
    background-color: #4b52ff;
    color: #fff;
    padding: 1em 2em;
    border-radius: 8px;
    border: 1px solid;
  }
`;

export default function PaymentHistoryPage() {
  const navigate = useNavigate();

  const handleClickSaenggibu = () => {
    navigate('./saenggibu');
  };

  const handleClickAllCare = () => {
    navigate('./allCare');
  };

  return (
    <PageLayout>
      <h1>결제 내역 및 환불</h1>
      <Navigation>
        <button onClick={handleClickSaenggibu}>생기부</button>
        <button onClick={handleClickAllCare}>올케어</button>
      </Navigation>
    </PageLayout>
  );
}
