import { useNavigate } from '@solidjs/router';
import deleteProvider from '../services/members/deleteProvider';
import deleteParedent from '../services/members/deleteParedent';
import { MemberType } from '../types/MemberType';

/* eslint-disable no-alert */
export default function useWithdrawMember() {
  const navigate = useNavigate();

  const confirmWithdraw = () => {
    const isConfirmed = window.confirm('정말 회원탈퇴 시키겠습니까????????');

    if (!isConfirmed) {
      alert('탈퇴가 취소되었습니다.');
      return false;
    }

    return true;
  };

  const withdrawMember = async ({ memberType, memberId }
  : { memberType : MemberType; memberId : string }) => {
    const isConfirmed = confirmWithdraw();

    if (!isConfirmed) return;

    const deleteMember = {
      provider: deleteProvider,
      paredent: deleteParedent,
    }[memberType];

    const { status, message } = await deleteMember(memberId);

    if (status === 'FAIL') {
      alert(message);
      return;
    }

    alert(message);

    navigate('/members');
  };

  return withdrawMember;
}
