import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

const convertISOToLocal = (time : string) => {
  const localTime = dayjs(time).local().format('YY-MM-DD / HH:mm');

  return localTime;
};

export default convertISOToLocal;
