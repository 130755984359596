import { useNavigate } from '@solidjs/router';
import postSettlementBills from '../services/settlementInfo/postSettlementBills';

/* eslint-disable no-alert */
export default function useSaveSettlementBills() {
  const navigate = useNavigate();

  const confirmWithdraw = () => {
    const isConfirmed = window.confirm('정말 정산을 최종 마무리하시겠습니까?\n모든 정산 과정이 끝난 후에 눌러주세요!');

    if (!isConfirmed) {
      alert('취소되었습니다.');
      return false;
    }

    return true;
  };

  const SaveSettlementBills = async (month : string) => {
    const isConfirmed = confirmWithdraw();

    if (!isConfirmed) return;

    const { status, message } = await postSettlementBills(month);

    if (status === 'FAIL') {
      alert(message);
      return;
    }

    alert(message);

    navigate('/');
  };

  return SaveSettlementBills;
}
