import { Match, Switch, For } from 'solid-js';

import { styled } from 'solid-styled-components';

import { useNavigate } from '@solidjs/router';

import { colors } from '../designSystem';

import postDeployRequest from '../services/postDeployRequest';

const Item = styled('li')`
  font-size: 1.1em;
  padding: 2em 3em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  list-style: none;
  border: 1px solid ${colors.border};

  header {
    display: flex;
    gap: 0.5em;
    align-items: center;
  }
`;

const Actions = styled('div')`
  display: flex;
  gap: 0.5em;

  button {
    font-size: 0.9em;
    background: ${colors.primary};
    color: ${colors.white};
    padding: 0.5em 0.75em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const Green = styled('div')`
  border-radius: 50%;
  width: 1em;
  height: 1em;
  background: ${colors.green};
`;

const Red = styled('span')`
  border-radius: 50%;
  width: 1em;
  height: 1em;
  background: ${colors.red};
`;

const Signal = () => {
  const isUp = true;

  return (
    <Switch fallback={<div>Not Found</div>}>
      <Match when={isUp}>
        <Green />
      </Match>
      <Match when={isUp}>
        <Red />
      </Match>
    </Switch>
  );
};

const instances = ['purchase-prod', 'search-prod', 'paredent-prod', 'provider-prod'];

function Instance(props: {
  name: string;
}) {
  const navigate = useNavigate();

  const handleClickLog = () => {
    navigate(`/logs/${props.name}`);
  };

  const handleClickDeploy = async () => {
    alert('배포를 시작합니다');

    await postDeployRequest(props.name);

    try {
      alert('배포가 끝났습니다. 새로고침 해주세요.');
    } catch (e) {
      alert('배포가 실패했습니다');
    }
  };

  const handleClickEnv = () => {
    navigate(`/environments/${props.name}`);
  };

  return (
      <Item>
        <header>
          <Signal />
          <h3>
            {props.name}
          </h3>
        </header>
        <Actions>
          <button onClick={handleClickDeploy}>재배포</button>
          <button onClick={handleClickLog}>로그보기</button>
          <button onClick={handleClickEnv}>환경변수 설정</button>
        </Actions>
      </Item>

  );
}

export default function Instances() {
  return (
    <ul>
      <For each={instances}>
        {(instance) => (
          <Instance name={instance} />
        )}
      </For>
    </ul>
  );
}
