import axios from 'axios';

type EnvEntry = {
  key: string;
  value: string;
};

export default async function fetchLog(instanceName: string): Promise<EnvEntry[]> {
  const { data } = await axios.get(`http://localhost:9200/environments?instanceName=${instanceName}`);
  return data;
}
