import { createResource } from 'solid-js';

import { styled } from 'solid-styled-components';

import Paredents from '../components/Paredents';

import Paredent from '../model/Paredent';

import fetchParedents from '../services/members/fetchParedents';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1em;
  margin-top: 1em;
`;

const Table = styled('table')`
  border-collapse: collapse;

  th {
    padding: 1em; 
    background: rgb(240, 241, 255);
  }

  tr {
    height: 2.3em;
  }

  td {
    text-align: center;
    padding: 0.8em;
    border: 0.5px solid rgb(217, 217, 217);
  }

  td:nth-child(even) {
    background: rgb(249, 249, 249);
  }
`;

export default function ParedentPage() {
  const [paredents] = createResource<Paredent[]>(fetchParedents);

  const newerFirst = (a: string, b: string) => new Date(b).valueOf() - new Date(a).valueOf();

  return (
    <Container>
      <h1>학생/학부모 회원 목록</h1>
      <Table>
        <thead>
          <tr>
            <th>id</th>
            <th>닉네임</th>
            <th>전화번호</th>
            <th>연령</th>
            <th>현재 이용권 수</th>
            <th>창체 주문 수</th>
            <th>세특 주문 수</th>
            <th>찜한 생기부 수</th>
            <th>현재 열람권 수</th>
            <th>열람한 프로필 수</th>
            <th>가입일</th>
            <th>회원 탈퇴</th>
            <th>열람권 추가</th>
            <th>이용권 추가</th>
          </tr>
        </thead>
        <tbody>
          <Paredents
            paredents={paredents()?.sort((a: Paredent, b: Paredent) => (
              newerFirst(a.signupAt, b.signupAt)
            ))}
          />
        </tbody>
      </Table>
    </Container>
  );
}
