import { createResource, createSignal } from 'solid-js';

import Provider from '../model/Provider';

import fetchProviders from '../services/members/fetchProviders';

export default function useProviders() {
  const [providersData] = createResource<Provider[]>(fetchProviders);

  const [
    filterPredicate,
    setFilterPredicate,
  ] = createSignal<(provider: Provider) => boolean>(() => true);

  const [
    sorterCompareFn,
    setSorterCompareFn,
  ] = createSignal<(a: Provider, b: Provider) => number>(
    (a: Provider, b: Provider) => new Date(b.signupAt).valueOf() - new Date(a.signupAt).valueOf());

  const providers = () => providersData()?.filter(filterPredicate()).sort(sorterCompareFn());

  const setFilter = (predicate: (provider: Provider) => boolean) => {
    setFilterPredicate(() => predicate);
  };

  const setSortBy = (fn: (p: Provider) => number, options?: { direction?: 'ASC' | 'DESC' }) => {
    if (options?.direction === 'ASC') {
      setSorterCompareFn(() => (a: Provider, b: Provider) => fn(b) - fn(a));
      return;
    }

    setSorterCompareFn(() => (a: Provider, b: Provider) => fn(a) - fn(b));
  };

  return {
    providers,
    setFilter,
    setSortBy,
  };
}
