import client from '../services/thanosClient';

export default function useAuthorize() {
  const authorize = async () => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN');

    if (!accessToken) {
      throw Error('침입자');
    }

    try {
      await client.get('/session', {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
    } catch (e) {
      throw Error('침입자');
    }
  };

  return authorize;
}
