import { styled } from 'solid-styled-components';

const PageLayout = styled('div')`
  max-width: 1024px;
  margin: 3em auto;

  h1 {
    font-size: 2em;
    margin-bottom: 1em;
    line-height: 1.5;
    text-align: center;
  }
`;

export default PageLayout;
