/* eslint-disable no-alert */
import { useNavigate } from '@solidjs/router';
import axios from 'axios';
import checkIsRefundable from '../services/paymentHistory/checkIsRefundable';
import refundPayment from '../services/paymentHistory/refundPayment';
import { ProductType } from '../types/ProductType';
import convertBankNameToBankCode from '../utils/convertBankNameToBankCode';

const usePaymentRefund = () => {
  const navigate = useNavigate();

  const processCheckIsRefundable = async ({ paymentId, productType }
  : { paymentId: string; productType: ProductType; }) => {
    try {
      const isRefundable = await checkIsRefundable({ paymentId, productType });

      if (!isRefundable) {
        alert('환불이 불가능합니다.\n(사유 : 사용된 상품이 존재 혹은 이미 환불된 결제건)');
      }

      return isRefundable;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        alert(error.response?.data.message);
        return false;
      }

      return false;
    }
  };

  const confirmRefund = () => {
    const isConfirmed = window.confirm('진짜 환불 하시겠습니까?');

    if (!isConfirmed) {
      alert('환불이 취소되었습니다.');
      return false;
    }

    return true;
  };

  const fetchRefundRequest = (method : string) => {
    if (method === '가상계좌') {
      const cancelReason = window.prompt('환불 사유를 입력해주세요.');

      const bankCode = convertBankNameToBankCode(
        window.prompt('환불 은행을 입력해주세요.\nNH농협은행, KB국민은행, 우리은행, 신한은행, IBK기업은행, 경남은행, 광주은행, DGB대구은행, 부산은행, 새마을금고, Sh수협은행, 우체국예금보험, 전북은행, 하나은행, 케이뱅크, 카카오뱅크'),
      );

      if (!bankCode) {
        alert('제대로된 은행을 입력해주세요.');
        return null;
      }

      const accountNumber = window.prompt('환불 계좌번호를 입력해주세요.\n-를 제거하고 넣어주세요.');
      const holderName = window.prompt('예금주를 입력해주세요.');

      if (!cancelReason || !bankCode || !accountNumber || !holderName) {
        alert('환불에 필요한 정보를 모두 입력해주세요!');
        return null;
      }

      const refundReceiveAccount = {
        bank: bankCode, accountNumber, holderName,
      };

      const refundRequest = {
        cancelReason, refundReceiveAccount,
      };

      return refundRequest;
    }

    const cancelReason = window.prompt('환불 사유를 입력해주세요.');

    if (!cancelReason) {
      alert('환불 사유는 필수입니다!');
      return null;
    }

    const refundRequest = { cancelReason };

    return refundRequest;
  };

  const refund = async ({ paymentId, productType, method }
  : { paymentId: string; productType: ProductType; method: string; }) => {
    const refundRequest = fetchRefundRequest(method);

    if (!refundRequest) return;

    const { status, message } = await refundPayment({
      paymentId, productType, method, refundRequest,
    });

    if (status === 'FAIL') {
      alert(message);
      return;
    }

    alert(message);

    navigate('/paymentHistory');
  };

  const processRefund = async ({ paymentId, productType, method }
  : { paymentId: string; productType: ProductType; method: string; }) => {
    const isRefundable = await processCheckIsRefundable({ paymentId, productType });

    if (!isRefundable) return;

    const isConfirmed = confirmRefund();

    if (!isConfirmed) return;

    await refund({ paymentId, productType, method });
  };

  return {
    processRefund,
  };
};

export default usePaymentRefund;
