import axios from 'axios';

type EnvEntry = {
  key: string;
  value: string;
};

export default async function postEnvEntries(envItems: EnvEntry[], instanceName: string) {
  await axios.post(`http://localhost:9200/environments?instanceName=${instanceName}`, envItems);
}
