import { For, createSignal } from 'solid-js';
import { styled } from 'solid-styled-components';
import settlementMonthStore from '../stores/settlementMonthStore';
import settlementMonthListGenerator from '../utils/settlementMonthListGenerator';

const Container = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 2em;
  gap: 1em;
    button {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      outline: none;
    }
`;

const Menu = styled('ul')`
  width: 10em;
  position: absolute;
  background-color: #FFF;
  border: 1px solid #666;
`;

const MenuItem = styled('li')`
  width: 100%;
  height: fit-content;
  list-style-type: none;
    button {
      width: 100%;
      border: 0;
      background-color: #FFF;
      padding-block: 0.25em;
      font-size: 0.75em;
      &:hover {
        background-color: #999;
      }
    }
`;

const SelectedMonth = styled('button')`
  width: 10em;
  border: 0;
  border-bottom: 3px solid #666;
  background-color: #fff;
  padding-bottom: 0.25em;
  font-size: 1em;
`;

const MonthSelect = styled('div')`
  position: relative;
`;

const SettlementMonthSelect = () => {
  const [isOpen, setSignal] = createSignal<boolean>(false);

  const toggle = () => setSignal(!isOpen());

  const isClosed = () => !isOpen();

  const handleClickMenu = (month : string) => {
    toggle();
    settlementMonthStore.setSettlementMonth(month);
  };

  return (
    <Container>
      <p>현재 선택된 정산달 : </p>
      <MonthSelect>
        <SelectedMonth onclick={toggle}>
          {settlementMonthStore.settlementMonth()}
        </SelectedMonth>
        <Menu hidden={isClosed()}>
          <For each={settlementMonthListGenerator()}>
            {(month) => (
              <MenuItem>
                <button onClick={() => handleClickMenu(month)}>
                  {month}
                </button>
              </MenuItem>
            )}
          </For>
        </Menu>
      </MonthSelect>
    </Container>
  );
};

export default SettlementMonthSelect;
