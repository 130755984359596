import PageLayout from '../layouts/PageLayout';

import Instances from '../components/Instances';

export default function AcamanchiroPage() {
  return (
    <PageLayout>
      <h1>Acamanchiro</h1>
      <Instances />
    </PageLayout>
  );
}
