import { styled } from 'solid-styled-components';

import { createSignal } from 'solid-js';
import Providers from '../components/Providers';

import useProviders from '../hooks/useProviders';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1em;
  margin-top: 1em;

  p {
    display: flex;
    margin-right: 1px;
  }
`;

const Option = styled('div')`
  display: flex;
  gap: 8px;
  margin-right: auto;
  margin-left: 1em;

  button {
    display: block;
  }
`;

const Table = styled('table')`
  border-collapse: collapse;

  th {
    padding: 1em; 
    background: rgb(240, 241, 255);
    min-width: 3em;
  }

  tr {
    height: 2.3em;
  }

  td {
    text-align: center;
    padding: 0.8em;
    border: 0.5px solid rgb(217, 217, 217);
  }

  td:nth-child(even) {
    background: rgb(249, 249, 249);
  }
`;

type OrderDirection = 'ASC' | 'DESC';

export default function ProviderPage() {
  const {
    providers,
    setFilter,
    setSortBy,
  } = useProviders();

  const [universityOrderDirection, setUniversityOrderDirection] = createSignal<OrderDirection>('ASC');

  const [openedCountOrderDirection, setOpenedCountOrderDirection] = createSignal<OrderDirection>('DESC');

  const handleClickAllProvider = () => {
    setFilter(() => true);
  };

  const handleClickResetSort = () => {
    setSortBy((provider) => new Date(provider.signupAt).valueOf(), {
      direction: 'ASC',
    });
  };

  const handleClickAdditionalInfo = () => {
    setFilter((provider) => provider.additionalInfo1);
  };

  const handleClickSaenggibuUpload = () => {
    setFilter((provider) => provider.isPdfUploaded);
  };

  const oposite = (direction: OrderDirection) => {
    if (direction === 'ASC') {
      return 'DESC';
    }
    return 'ASC';
  };

  const handleClickSortUniversity = () => {
    const direction = oposite(universityOrderDirection());

    setSortBy((provider) => provider.universityNum, {
      direction,
    });
    setUniversityOrderDirection(direction);
  };

  const handleClickSortOpenedCount = () => {
    const direction = oposite(openedCountOrderDirection());

    setSortBy((provider) => provider.openedCount, {
      direction,
    });
    setOpenedCountOrderDirection(direction);
  };

  return (
    <Container>
      <h1>합격생 회원 목록</h1>
      <Option>
        <p>전체: {providers()?.length}</p>
        <button onClick={handleClickAllProvider}>전체 합격생</button>
        <button onClick={handleClickAdditionalInfo}>추가정보 입력한 합격생</button>
        <button onClick={handleClickSaenggibuUpload}>생기부 업로드한 합격생</button>
        <button onClick={handleClickSortUniversity}>대학 정렬</button>
        <button onClick={handleClickSortOpenedCount}>조회수 정렬</button>
        <button onClick={handleClickResetSort}>정렬 초기화</button>
      </Option>
      <Table>
        <thead>
          <tr>
            <th>id</th>
            <th>이름</th>
            <th>닉네임</th>
            <th>전화번호</th>
            <th>대학</th>
            <th>학과</th>
            <th>전형</th>
            <th>조회수</th>
            <th>생기부 업로드</th>
            <th>개인정보 가리기</th>
            <th>가입일</th>
            <th>회원 탈퇴</th>
          </tr>
        </thead>
        <tbody>
          <Providers
            providers={providers()}
          />
        </tbody>
      </Table>
    </Container>
  );
}
