export const colors = {
  primary: '#4b52ff',
  white: '#fff',
  red: 'red',
  green: 'green',
  border: '#E1E4EF',
};

// TODO: delete this
export const x = '';
