import { For } from 'solid-js';

import Provider from '../model/Provider';
import useWithdrawMember from '../hooks/useWithdrawMember';
import convertISOToLocal from '../utils/convertISOToLocal';

export default function Providers(props: {
  providers: Provider[] | undefined
}) {
  const withdrawMember = useWithdrawMember();
  return (
    <For
      each={props.providers}
      fallback={<div>Loading...</div>}
    >
      {(provider) => (
        <tr>
          <td>{provider.id}</td>
          <td>{provider.name}</td>
          <td>{provider.nickName}</td>
          <td>{provider.phoneNumber}</td>
          <td>{provider.universityName}</td>
          <td>{provider.major}</td>
          <td>{provider.appType}</td>
          <td>{provider.openedCount}</td>
          <td>{provider.isPdfUploaded ? 'O' : 'X'}</td>
          <td>{provider.isRedacted ? 'O' : 'X'}</td>
          <td>{convertISOToLocal(provider.signupAt.toLocaleString())}</td>
          <td>
              <button onClick={() => withdrawMember({ memberType: 'provider', memberId: provider.id })}>
                회원 탈퇴시키기
              </button>
            </td>
        </tr>
      )}
    </For>
  );
}
