import { ProductType } from '../types/ProductType';
import client from './thanosClient';

export default async function postTicket({
  productType, paredentId, remark,
}: {
  productType: ProductType; paredentId: string; remark: string;
}) {
  const response = await client.post(`/tickets?productType=${productType}`, {
    paredentId,
    remark,
  }).catch((error) => error.response);

  const { data } = response;

  return data;
}
