import { styled } from 'solid-styled-components';

import { useNavigate } from '@solidjs/router';

import PageLayout from '../layouts/PageLayout';

import useValidateEmail from '../hooks/useValidateEmail';

import userStore from '../stores/userStore';

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2em;

  img {
    width: 50%;
  }
`;

const LogInForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    background-color: #4b52ff;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

const Field = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

export default function LoginPage() {
  const validate = useValidateEmail();

  const navigate = useNavigate();

  const handleInput = (e: any) => {
    const { value } = e.currentTarget;
    userStore.changeEmail(value);
  };

  const handleClick = async () => {
    try {
      await validate(userStore.email());
      navigate('/otp');
    } catch (e: any) {
      alert(e.message);
    }
  };

  return (
    <PageLayout>
      <h1>Thanos</h1>
      <Body>
        <img src="https://user-images.githubusercontent.com/37819666/153756247-4239160b-31f6-4f00-9b83-a821d4e288c9.gif" />
        <LogInForm>
          <Field>
            <input placeholder='이메일을 입력해주세요' type="text" onInput={handleInput} />
          </Field>
          <button type="button" onClick={handleClick}>
            로그인
          </button>
        </LogInForm>
      </Body>
    </PageLayout>
  );
}
