import { styled } from 'solid-styled-components';

import { useParams } from '@solidjs/router';

import useSubscribeLog from '../hooks/useSubscribeLog';

import PageLayout from '../layouts/PageLayout';

const Terminal = styled('p')`
  padding: 1em 2em;
  padding-bottom: 5em;
  height: 40em;
  
  border-radius: 10px;
  font-family: 'Courier New', Courier, monospace;
  white-space: pre-wrap;
  background: rgb(27, 14, 65);
  color: #86efac;
  line-height: 1.8;
  overflow: scroll;
`;

export default function LogPage() {
  const { instanceName } = useParams();

  const log = useSubscribeLog({ instanceName });

  return (
    <PageLayout>
      <h1>{instanceName}</h1>
      <Terminal>{log()}</Terminal>
    </PageLayout>
  );
}
