import { ProductType } from '../../types/ProductType';
import { RefundRequest } from '../../types/RefundRequest';
import client from '../thanosClient';

const refundPayment = async ({
  paymentId, productType, method, refundRequest,
}: {
  paymentId: string;
  productType: ProductType;
  method: string;
  refundRequest: RefundRequest;
}) => {
  const response = await client.post(
    `/payments/${paymentId}/refund?productType=${productType}&method=${method}`,
    refundRequest,
  ).catch((error) => error.response);

  const { data } = response;

  return data;
};

export default refundPayment;
