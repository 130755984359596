import { styled } from 'solid-styled-components';
import _ from 'lodash';

import { SettlementInfo } from '../types/SettlementInfo';

import SettlementBills from './SettlementBills';

import useSaveSettlementBills from '../hooks/useSaveSettlementBills';

const Container = styled('div')`
  width: fit-content;
  padding: 2em;
  border: 2px solid #666;
  border-radius: 10px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  p {
    font-size: 1.5em;
    font-weight: 500;
  }
`;

const Table = styled('table')`
  border-collapse: collapse;

  th {
    white-space: nowrap;
    padding: 1em;
    background: rgb(240, 241, 255);
  }

  tr {
    height: 2.3em;
  }

  td {
    text-align: center;
    min-width: 4.5em;
    padding: 0.8em;
    border: 0.5px solid rgb(217, 217, 217);
  }

  td:nth-child(even) {
    background: rgb(249, 249, 249);
  }
`;

const Head = styled('div')`
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SettlementButton = styled('button')`
  background: #4b52ff;
  border-radius: 10px;
  padding-block: 0.5em;
  padding-inline: 1em;
  color: #fff;
  font-size: 1em;
  border: 0;
  cursor: pointer;
`;

const TotalOpenCount = styled('p')`
   font-size: 1em;
   font-weight: 500;
`;

const SettlementInfoBoard = (props: {
  settlementInfo: SettlementInfo;
}) => {
  const yearMonth = () => {
    const [year, month] = props.settlementInfo.month.split('-');

    return {
      year,
      month,
    };
  };

  const totalOpenCount = () => _.sumBy(props.settlementInfo?.settlementBills, 'purchaseCount');

  const saveSettlementBills = useSaveSettlementBills();

  return (
    <Container>
      <Head>
        <p>
          {yearMonth().year}년 {yearMonth().month}월 정산 일지
        </p>
        <SettlementButton hidden={props.settlementInfo.isSettled}
        onclick={() => saveSettlementBills(props.settlementInfo.month)}>
          최종 정산하기
        </SettlementButton>
        <TotalOpenCount>총 열람 수 : {totalOpenCount()}회</TotalOpenCount>
      </Head>
      <Table>
        <thead>
          <tr>
            <th>id</th>
            <th>이름</th>
            <th>단가</th>
            <th>열람수</th>
            <th>금액</th>
            <th>세금</th>
            <th>예상 지급액</th>
            <th>은행명</th>
            <th>계좌번호</th>
            <th>주민번호</th>
          </tr>
        </thead>
        <tbody>
          <SettlementBills
            settlementBills={props.settlementInfo.settlementBills}
          />
        </tbody>
      </Table>
    </Container>
  );
};

export default SettlementInfoBoard;
