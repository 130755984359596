import { styled } from 'solid-styled-components';

import { createResource } from 'solid-js';
import PaymentTable from '../styles/PaymentTable';
import PaymentHistories from '../components/PaymentHistories';
import fetchPaymentHistories from '../services/paymentHistory/fetchPaymentHistories';

export default function AllCarePaymentHistoryPage() {
  const [paymentHistories] = createResource(() => fetchPaymentHistories('allCare'));

  const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1em;
  margin-top: 1em;
`;
  return (
    <Container>
      <h1>올케어 결제내역</h1>
      <PaymentTable>
      <thead>
        <tr>
          <th>Index</th>
          <th>결제 Id</th>
          <th>주문명</th>
          <th>가격</th>
          <th>결제 수단</th>
          <th>학생/학부모 Id</th>
          <th>닉네임</th>
          <th>결제 생성 시간</th>
          <th>환불</th>
        </tr>
      </thead>
      <tbody>
        <PaymentHistories
          productType="allCare"
          paymentHistories={paymentHistories()}
        />
      </tbody>
    </PaymentTable>
    </Container>
  );
}
