import { styled } from 'solid-styled-components';

import { useNavigate } from '@solidjs/router';

import PageLayout from '../layouts/PageLayout';

import useLogin from '../hooks/useLogin';

import userStore from '../stores/userStore';

const Body = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2em;
  height: 80vh;

  img {
    width: 50%;
  }
`;

const OtpForm = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    background-color: #4b52ff;
    color: #fff;
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

const Field = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  input {
    padding: 0.5em 1em;
    border-radius: 4px;
    border: 1px solid;
  }
`;

export default function OtpPage() {
  const login = useLogin();

  const navigate = useNavigate();

  const handleInput = (e: any) => {
    const { value: newCode } = e.currentTarget;
    userStore.changeCode(newCode);
  };

  const handleClick = async () => {
    try {
      await login({
        code: userStore.code(),
        email: userStore.email(),
      });

      navigate('/');
    } catch (e) {
      alert('인증에 실패했습니다. 코드를 확인해주세요.');
    }
  };

  return (
    <PageLayout>
      <Body>
        <OtpForm>
          <p>이메일로 전송된 암호를 입력해주세요</p>
          <Field>
            <input placeholder='xxx xxx' type="text" onInput={handleInput} />
          </Field>
          <button type="button" onClick={handleClick}>
            입력하기
          </button>
        </OtpForm>
      </Body>
    </PageLayout>
  );
}
