import client from '../thanosClient';

export default async function postSettlementBills(month : string) {
  const response = await client.post(
    `/providers/settlementBills?month=${month}`,
  ).catch((error) => error.response);

  const { data } = response;

  return data;
}
