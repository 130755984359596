import { createResource } from 'solid-js';

import { styled } from 'solid-styled-components';

import fetchPaymentHistories from '../services/paymentHistory/fetchPaymentHistories';

import PaymentHistories from '../components/PaymentHistories';
import PaymentTable from '../styles/PaymentTable';

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 1em;
  margin-top: 1em;
`;

export default function SaenggibuPaymentHistoryPage() {
  const [paymentHistories] = createResource(() => fetchPaymentHistories('saenggibu'));

  return (
    <Container>
      <h1>생기부 결제내역</h1>
      <PaymentTable>
        <thead>
          <tr>
            <th>Index</th>
            <th>결제 Id</th>
            <th>주문명</th>
            <th>가격</th>
            <th>결제 수단</th>
            <th>학생/학부모 Id</th>
            <th>닉네임</th>
            <th>결제 생성 시간</th>
            <th>환불</th>
          </tr>
        </thead>
        <tbody>
          <PaymentHistories
            productType="saenggibu"
            paymentHistories={paymentHistories()}
          />
        </tbody>
      </PaymentTable>
    </Container>
  );
}
