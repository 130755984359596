import { createSignal } from 'solid-js';

const [code, setCode] = createSignal('');

const [email, setEmail] = createSignal('');

const changeCode = (newCode: string) => {
  setCode(newCode);
};

const changeEmail = (newEmail: string) => {
  setEmail(newEmail);
};

export default {
  code,
  changeCode,
  email,
  changeEmail,
};
