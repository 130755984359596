import { Index, createResource } from 'solid-js';

import { useParams } from '@solidjs/router';

import { styled } from 'solid-styled-components';

import PageLayout from '../layouts/PageLayout';

import fetchEnvironmentVariables from '../services/fetchEnvironmentVariables';

import { colors } from '../designSystem';

import postEnvEntries from '../services/postEnvEntries';

const Row = styled('div')`
  display: flex;
  padding: 1em 2em;
  border: 1px solid ${colors.border};
`;

const TableHeader = styled('div')`
  display: flex;
  font-weight: 700;
  padding: 1em 2em;
  border: 1px solid ${colors.border};
  background: ${colors.border};
`;

const Column = styled('div')`
  min-width: 20em;
  display: flex;
  align-items: center;

  input {
    min-width: 45em;
    padding: 0.5em 1em;
  }
`;

function Loading() {
  return (
    <div>
      loading...
    </div>
  );
}

function Item(props: {
  key: string;
  value: string;
  onInput: (key: string, value: string) => void;
}) {
  return (
    <Row>
      <Column>{props.key}</Column>
      <Column>
        <input type="text" value={props.value} onInput={(e) => {
          const { value } = e.currentTarget;
          props.onInput(props.key, value);
        }} />
      </Column>
    </Row>
  );
}

const SubmitWrapper = styled('div')`
  margin-block: 1em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    font-size: 1.5em;
    padding: 1em 1.5em;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    color: ${colors.white};
    background: ${colors.primary};
  }
`;

export default function EnvironmentPage() {
  const { instanceName } = useParams();

  const [environmentEntries, { mutate }] = createResource(instanceName, fetchEnvironmentVariables);

  const handleInput = (key: string, value: string) => {
    mutate((prev) => prev?.map((item) => ({
      key: item.key,
      value: item.key === key ? value : item.value,
    })));
  };

  const handleSubmit = async () => {
    const entries = environmentEntries();
    if (!entries) {
      return;
    }
    await postEnvEntries(entries, instanceName);
  };

  return (
    <PageLayout>
      <h1>환경변수 수정</h1>
      <TableHeader>
        <Column>Key</Column>
        <Column>Value</Column>
      </TableHeader>
      <ul>
        <Index each={environmentEntries()} fallback={<Loading />}>
          {(entry) => (
            <Item
              key={entry().key}
              value={entry().value}
              onInput={handleInput}
            />
          )}
        </Index>
      </ul>
      <SubmitWrapper>
        <button onClick={handleSubmit}>제출하기</button>
      </SubmitWrapper>
    </PageLayout>
  );
}
