import { render } from 'solid-js/web';

import App from './App';

function main() {
  const rootElement = document.getElementById('root');

  if (!rootElement) {
    throw Error('root element not found!');
  }

  render(App, rootElement);
}

main();
