import { useNavigate } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import PageLayout from '../layouts/PageLayout';

const NavigateSection = styled('form')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    font-size: 30px;
    background-color: #4b52ff;
    color: #fff;
    padding: 1em 2em;
    border-radius: 8px;
    border: 1px solid;
    width: 350px;
  }
`;
export default function HomePage() {
  const navigate = useNavigate();

  const handleClickAcamanchiro = async () => {
    navigate('/acamanchiro');
  };

  const handleClickMembers = async () => {
    navigate('/members');
  };

  const handleClickPaymentHistory = async () => {
    navigate('/paymentHistory');
  };

  const handleClickSettlementInfo = async () => {
    navigate('/settlementInfo');
  };

  return (
    <PageLayout>
      <h1>홈페이지!</h1>
      <NavigateSection>
        <button type="button" onClick={handleClickAcamanchiro}>Acamanchiro</button>
        <button type="button" onClick={handleClickMembers}>회원 목록</button>
        <button type="button" onClick={handleClickPaymentHistory}>결제 내역 및 환불</button>
        <button type="button" onClick={handleClickSettlementInfo}>합격생 정산 정보</button>
      </NavigateSection>
    </PageLayout>
  );
}
