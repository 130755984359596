import { styled } from 'solid-styled-components';

const PaymentTable = styled('table')`
  border-collapse: collapse;

  th {
    padding: 1em; 
    background: rgb(240, 241, 255);
    min-width: 3em;
  }

  tr {
    height: 2.3em;
  }

  td {
    text-align: center;
    padding: 0.8em;
    border: 0.5px solid rgb(217, 217, 217);
  }

  td:nth-child(even) {
    background: rgb(249, 249, 249);
  }
`;

export default PaymentTable;
