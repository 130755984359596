const bankList : {
  [key: string]: string;
} = {
  NH농협은행: '11',
  KB국민은행: '06',
  우리은행: '20',
  신한은행: '88',
  IBK기업은행: '03',
  경남은행: '39',
  광주은행: '34',
  DGB대구은행: '31',
  부산은행: '32',
  새마을금고: '45',
  Sh수협은행: '07',
  우체국예금보험: '71',
  전북은행: '37',
  하나은행: '81',
  케이뱅크: '89',
  카카오뱅크: '90',
};

export default function convertBankNameToBankCode(bankName : string | null) {
  if (!bankName) {
    return null;
  }

  return bankList[bankName] ? bankList[bankName] : null;
}
