import { For } from 'solid-js';

import { SettlementBill } from '../types/SettlementInfo';

import taxCalculator from '../utils/taxCalculator';

export default function SettlementBills(props: {
  settlementBills: SettlementBill[] | undefined
}) {
  return (
    <For
      each={props.settlementBills}
      fallback={<div>Loading...</div>}
    >
      {(bill) => {
        const {
          id,
          name,
          residentRegistrationNum,
          revenuePerPurchase,
          purchaseCount,
          bankName,
          accountNum,
        } = bill;

        const revenue = purchaseCount * revenuePerPurchase;

        const tax = taxCalculator(revenue);

        const afterTax = revenue - tax;
        return (
          <tr>
            <td>{id}</td>
            <td>{name}</td>
            <td>{revenuePerPurchase}</td>
            <td>{purchaseCount}</td>
            <td>{revenue}</td>
            <td>{tax}</td>
            <td>{afterTax}</td>
            <td>{bankName}</td>
            <td>{accountNum}</td>
            <td>{residentRegistrationNum}</td>
          </tr>
        );
      }}
    </For>
  );
}
