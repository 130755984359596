import { ProductType } from '../../types/ProductType';
import client from '../thanosClient';

const checkIsRefundable = async ({ paymentId, productType }
: { paymentId: string; productType: ProductType }) => {
  const { data } = await client.get(`/payments/${paymentId}/isRefundable?productType=${productType}`);

  return data.isRefundable;
};

export default checkIsRefundable;
