import { For } from 'solid-js';

import useAddTicket from '../hooks/useAddTicket';
import useWithdrawMember from '../hooks/useWithdrawMember';
import Paredent from '../model/Paredent';
import convertISOToLocal from '../utils/convertISOToLocal';

export default function Paredents(props: {
  paredents: Paredent[] | undefined
}) {
  const withdrawMember = useWithdrawMember();

  const addTicket = useAddTicket();

  return (
    <For
      each={props.paredents}
      fallback={<h1>로딩...</h1>}
    >
      {(paredent) => (
        <tr >
          <td>{paredent.id}</td>
          <td>{paredent.nickName}</td>
          <td>{paredent.phoneNumber}</td>
          <td>{paredent.ageRange || '미상'}</td>
          <td>{paredent.usableAllCareTicketCount}</td>
          <td>{paredent.changcheRequest}</td>
          <td>{paredent.saeteukRequest}</td>
          <td>{paredent.wishCount}</td>
          <td>{paredent.usableOpenTicketCount}</td>
          <td>{paredent.openCount}</td>
          <td>{convertISOToLocal(paredent.signupAt.toLocaleString())}</td>
          <td>
            <button onClick={() => withdrawMember({ memberType: 'paredent', memberId: paredent.id })}>
              회원 탈퇴시키기
            </button>
          </td>
          <td>
            <button onClick={() => addTicket({ productType: 'saenggibu', paredentId: paredent.id, nickName: paredent.nickName })}>
              추가
            </button>
          </td>
          <td>
            <button onClick={() => addTicket({ productType: 'allCare', paredentId: paredent.id, nickName: paredent.nickName })}>
              추가
            </button>
          </td>
        </tr>
      )}
    </For>
  );
}
