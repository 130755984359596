import { useNavigate } from '@solidjs/router';
import { styled } from 'solid-styled-components';
import PageLayout from '../layouts/PageLayout';

const Navigation = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  button {
    font-size: 30px;
    background-color: #4b52ff;
    color: #fff;
    padding: 1em 2em;
    border-radius: 8px;
    border: 1px solid;
    width: 350px;
  }
`;

export default function MembersPage() {
  const navigate = useNavigate();

  const handleClickParedent = () => {
    navigate('./paredent');
  };

  const handleClickProvider = () => {
    navigate('./provider');
  };
  return (
    <PageLayout>
      <h1>회원 목록</h1>
      <Navigation>
        <button onClick={handleClickParedent}>학생/학부모</button>
        <button onClick={handleClickProvider}>합격생</button>
      </Navigation>
    </PageLayout>
  );
}
