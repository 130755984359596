import { Route, Router, Routes } from '@solidjs/router';

import AcamanchiroPage from './pages/AcamanchiroPage';
import AllCarePaymentHistoryPage from './pages/AllCarePaymentHistoryPage';
import EnvironmentPage from './pages/EnvironmentPage';
import HomePage from './pages/HomePage';
import LogPage from './pages/LogPage';
import LoginPage from './pages/LoginPage';
import MembersPage from './pages/MembersPage';
import OtpPage from './pages/OtpPage';
import ParedentPage from './pages/ParedentPage';
import PaymentHistoryPage from './pages/PaymentHistoryPage';
import ProviderPage from './pages/ProviderPage';
import RouteGuard from './pages/RouteGuard';
import SaenggibuPaymentHistoryPage from './pages/SaenggibuPaymentHistoryPage';
import SettlementInfoPage from './pages/SettlementInfoPage';

export default function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" component={RouteGuard}>
          <Route path="/" component={HomePage} />
          <Route path="/acamanchiro" component={AcamanchiroPage} />
          <Route path="/members">
            <Route path="/" component={MembersPage} />
            <Route path="/paredent" component={ParedentPage} />
            <Route path="/provider" component={ProviderPage} />
          </Route>
          <Route path="/paymentHistory">
            <Route path="/" component={PaymentHistoryPage} />
            <Route path="/saenggibu" component={SaenggibuPaymentHistoryPage} />
            <Route path="/allCare" component={AllCarePaymentHistoryPage} />
          </Route>
          <Route path="/settlementInfo" component={SettlementInfoPage} />
          <Route path="/logs/:instanceName" component={LogPage} />
          <Route path="/environments/:instanceName" component={EnvironmentPage} />
        </Route>
        <Route path="/otp" component={OtpPage} />
        <Route path="/login" component={LoginPage} />
      </Routes>
    </Router>
  );
}
