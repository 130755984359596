import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

const settlementMonthListGenerator = () => {
  const dateNow = dayjs();

  const currentYearMonth = dayjs(`${dateNow.year()}-${dateNow.month() + 1}`);

  const startYearMonth = dayjs('2023-07');

  const monthDuration = Math.ceil(dayjs.duration(currentYearMonth
    .diff(startYearMonth)).asMonths());

  const settlementMonthList = [...new Array(monthDuration)]
    .map((item, index) => {
      const date = startYearMonth.add(index, 'month');

      const year = date.year();

      const month = date.month() + 1;

      return `${year}-${month > 9 ? month : `0${month}`}`;
    });

  return settlementMonthList;
};

export default settlementMonthListGenerator;
