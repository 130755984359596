import { For } from 'solid-js';
import PaymentHistory from '../model/PaymentHistory';
import { ProductType } from '../types/ProductType';
import usePaymentRefund from '../hooks/usePaymentRefund';
import convertISOToLocal from '../utils/convertISOToLocal';

export default function PaymentHistories(props: {
  paymentHistories: PaymentHistory[] | undefined;
  productType: ProductType
}) {
  const { processRefund } = usePaymentRefund();

  return (
    <For each={props.paymentHistories} fallback={<div>Loading...</div>}>
      {(paymentHistory, index) => (
          <tr>
            <td>{index() + 1}</td>
            <td>{paymentHistory.id}</td>
            <td>{paymentHistory.orderName}</td>
            <td>{paymentHistory.amount}</td>
            <td>{paymentHistory.method}</td>
            <td>{paymentHistory.paredentId}</td>
            <td>{paymentHistory.paredentNickName}</td>
            <td>{convertISOToLocal(paymentHistory.createdAt)}</td>
            <td>
              <button onClick={() => processRefund({
                paymentId: paymentHistory.id,
                productType: props.productType,
                method: paymentHistory.method,
              })}>
                환불하기
              </button>
            </td>
          </tr>
      )}
    </For>
  );
}
