import { Show, createResource } from 'solid-js';
import PageLayout from '../layouts/PageLayout';

import settlementMonthStore from '../stores/settlementMonthStore';

import SettlementInfoBoard from '../components/SettlementInfoBoard';
import SettlementMonthSelect from '../components/SettlementMonthSelect';
import { SettlementInfo } from '../types/SettlementInfo';
import fetchSettlementInfo from '../services/settlementInfo/fetchSettlementInfo';

export default function SettlementInfoPage() {
  const [settlementInfo] = createResource<SettlementInfo, string>(
    settlementMonthStore.settlementMonth,
    fetchSettlementInfo,
  );

  return (
    <PageLayout>
      <h1>합격생 정산 정보</h1>
      <SettlementMonthSelect />
      <Show when={!settlementInfo.loading && settlementInfo()} fallback={<h1>로딩...</h1>}>
        {(accessor) => <SettlementInfoBoard settlementInfo={accessor()}/>}
      </Show>
    </PageLayout>
  );
}
