import { createWS } from '@solid-primitives/websocket';

import { createSignal, onCleanup } from 'solid-js';

export default function useSubscribeLog({ instanceName }: { instanceName: string }) {
  const ws = createWS(`ws://localhost:9100/logs/${instanceName}`);

  const [log, setLog] = createSignal<string>('');

  const handleEvent = (event: any) => {
    setLog((prev) => `${prev}\n${event.data}`);
  };

  ws.onmessage = handleEvent;

  onCleanup(() => {
    ws.removeEventListener('onmessage', handleEvent);
  });

  return log;
}
