import { useNavigate } from '@solidjs/router';
import postTicket from '../services/postTicket';
import { ProductType } from '../types/ProductType';

/* eslint-disable no-alert */
export default function useAddTicket() {
  const navigate = useNavigate();

  const confirmAddTicket = ({ nickName, productType }
  : { nickName: string; productType : ProductType; }) => {
    const product = {
      saenggibu: '생기부 열람권',
      allCare: '올케어 이용권',
    }[productType];

    const isConfirmed = window.confirm(`${nickName}님에게 ${product}을 추가하시겠습니까?`);

    if (!isConfirmed) {
      alert('취소되었습니다.');
      return false;
    }

    return true;
  };

  const addTicket = async ({ productType, paredentId, nickName }
  : { productType : ProductType; paredentId : string; nickName: string }) => {
    const remark = window.prompt('추가 사유를 입력해주세요.');

    if (!remark) {
      alert('추가 사유는 필수입니다.');
      return;
    }

    const isConfirmed = confirmAddTicket({ nickName, productType });

    if (!isConfirmed) return;

    const { status, message } = await postTicket({ productType, paredentId, remark });

    if (status === 'FAIL') {
      alert(message);
      return;
    }

    alert(message);

    navigate('/members');
  };

  return addTicket;
}
