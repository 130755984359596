import { AxiosError } from 'axios';
import client from '../thanosClient';

export default async function fetchSettlementInfo(month : string) {
  try {
    const { data } = await client.get(`/providers/settlementInfo?month=${month}`);

    return data.data;
  } catch (error) {
    if (error instanceof AxiosError) {
      alert(error.response?.data?.message);
    }
    return {};
  }
}
