import client from '../services/thanosClient';

export default function useValidateEmail() {
  const validate = async (email: string) => {
    try {
      await client.get(`/access-code?email=${email}`);
    } catch (e: any) {
      throw Error('등록되지 않은 이메일 입니다');
    }
  };

  return validate;
}
